export const FaqsItems = [
{
///////////////////////////////////
entryTitle: 'TRADING RULES',
///////////////////////////////////
items: [
{
  question: 'What are the rules for the Assessment account?',
  answer: `
<p class="mt-2">
• 5% Daily Loss Limit (hard breach)
</p>
<p class="mt-2">
• 6% Max Trailing Drawdown (hard breach)
</p>
<p class="mt-2">
• Must place a trade once every 30 days (hard breach)
</p>
<p class="mt-2">
• Stop-loss required for each trade (soft breach) unless you have purchased the extra add-on to bypass this rule
<p class="mt-2">
• Flat for the weekend – all positions closed on Friday at 3:45PM EST (soft breach) unless you have purchased the extra add-on to bypass this rule
`},

{
  question: 'What is the difference between a Hard Breach and Soft Breach rule?',
  answer: `
<p class="mt-2">
• Soft breach means that we will close all trades that have violated the rule. However, you can continue trading in your Assessment or Funded account.
</p>
<p class="mt-2">
• Hard breach means that you violated either the Daily Loss Limit or Max Trailing Drawdown rule. Both rules constitute a hard breach. In the event you have a hard breach, you will fail the Assessment or have your Funded account taken away.
</p>
`},

{
  question: 'How do you calculate the 5% Daily Loss Limit?',
  answer: `
<p class="mt-2">
The Daily Loss Limit is the maximum your account can lose in any given day. Daily Loss Limit is calculated using the previous day balance which resets at 5 PM EST. Unlike other firms, we do NOT base our calculations on previous day equity since the balance only model allows you to scale profits without fear of losing your account. The Daily Stop compounds with the increase in your account.
</p>
<p class="mt-2">
<b>Example:</b> if your prior day's end of day balance (5pm EST) was $100,000, your account would violate the daily stop loss limit if your equity reached $95,000 during the day. If your floating equity is +$5,000 on a $100,000 account, your new- day (5pm EST) max loss is based on your balance from the previous day ($100,000). So, your daily loss limit would still be $95,000.
</p>
`},

{
  question: 'How do you calculate the 6% Max Trailing Drawdown?',
  answer: `
<p class="mt-2">
The Maximum Trailing Drawdown is initially set at 6% and trails (using CLOSED BALANCE - NOT equity) your account until you have achieved a 6% return in your account. Once you have achieved a 6% return the Maximum Trailing Drawdown no longer trails and is permanently locked in at your starting balance. This allows for more trading flexibility.

</p>
<p class="mt-2">
<b>Example:</b> If your starting balance is $100,000, you can drawdown to $94,000 before you would violate the Maximum Trailing Drawdown rule. Then for example let's say you take your account to $102,000 in CLOSED BALANCE. This is your new high-water mark, which would mean your new Maximum Trailing Drawdown would be $96,000. Next, let's say you take your account to $106,000 in CLOSED BALANCE, which would be your new high-water mark. At this point your Maximum Trailing Drawdown would be locked in at your starting balance of $100,000. So, regardless of how high your account goes, you would only breach this rule if your account drew back down to $100,000 (note, you can still violate the daily drawdown). For example, if you take your account to $170,000, as long as you do not drawdown more than 5% in any given day, you would only breach if your account equity reaches $100,000.
</p>
<p class="mt-2">

</p>
`},

{
  question: 'Why do I have to place a stop loss on trades?',
  answer: `
<p class="mt-2">
In line with sound risk management practices, we require a stop loss on every trade. If you fail to place a stop loss at the time of placing the trade/order, we will close the trade. This is only soft breach rule, so you can continue trading in your account.
</p>
`},

{
  question: 'Can I hold positions over the weekend?',
  answer: `
<p class="mt-2">
We require all trades to be closed by 3:45pm EST on Friday. Any trades left open after this time will automatically be closed. Note, this is only a soft breach and you will be able to continue trading once the markets reopen.
</p>
<p class="mt-2">
<b>You can purchase an extra add-on to your trading account that bypasses this weekend rule.</b>
</p>
`},

{
  question: 'What is 1 lot equal to on the Trading Platform?',
  answer: `
<p class="mt-2">
  • <b>Forex</b> - 1 lot = $100k notional
</p>
<p class="mt-2">
  • <b>Index</b> - 1 lot = 10 Contracts
</p>
<p class="mt-2">
  • <b>Cryptos</b> - 1 lot = 1 coin
</p>
<p class="mt-2">
  • <b>Silver</b> - 1 lot = 5000 ounces
</p>
<p class="mt-2">
  • <b>Gold</b> - 1 lot = 100 ounces
</p>
<p class="mt-2">
  • <b>Oil</b> - 1 lot = 1000 barrels
`},

{
  question: 'Is there a breach for inactivity?',
  answer: `
<p class="mt-2">
Yes. If you do not place a trade at least once every 30 days on your account, we will consider you inactive and your account will be breached.
</p>
<p class="mt-2">
<b>Following an inactivity breach the assessment account is deleted from the broker's database and is subsequently impossible to retrieve. An inactivity breach is an IRREVERSIBLE event.</b>
</p>
`},

]},

{
  ///////////////////////////////////
  entryTitle: 'FUNDED ACCOUNTS',
  ///////////////////////////////////
  items: [
  {
    question: 'How Long does it take to receive my Funded account?',
    answer: `
  <p class="mt-2">
  Upon passing your Assessment, you will receive a contract via your user dashboard. It can take up to 24-48 business hours to receive the live account once the contract is signed.
  </p>
   `},

  {
    question: 'Once I pass the Assessment am I provided with a demo or live account?',
    answer: `
  <p class="mt-2">
  Once you pass the Assessment, we provide you with a live account, backed by our capital.  The capital in your Funded Account is notional and may not match the amount of capital on deposit with the Broker.  A Funded Account is notionally funded when actual funds in the account (i.e., the equity in a Funded Account represented by the amount of capital) differs from the nominal account size (i.e., the size of the Funded Account that establishes the initial account value and level of trading). Notional funds are the difference between nominal account size and actual capital in a Funded Account.
  </p>
  <p class="mt-2">
  Use of notional funding does not change the trading level or that the account may trade in any manner differently than if notional funds were not used. In particular, the same conditions and rules applicable to a soft breach, hard breach, Daily Loss Limit, Max Trailing Drawdown, stop loss and position limits apply.
  </p>
  `},

  {
    question: 'Do we manipulate the pricing or executions  you receive in your Funded Account?  ',
    answer: `
  <p class="mt-2">
  No.  We operate at an arm's length with the Broker.  All market pricing and trade executions are provided by the Broker and are not changed or modified by us.
  </p>

  <p class="mt-2">
  Additionally, we do not mark up transaction costs established by the Broker through adjusting bid-offer spreads, markups/markdowns, commission charges or swaps.
  </p>
  `},

  {
    question: 'Who is the counterparty to my trades?  ',
    answer: `
  <p class="mt-2">
  For purposes of managing risk and minimizing transaction costs, we may offset or negate market risk and act as the direct counterparty to certain trades initiated in the Account.  Such trades are executed at prices provided by the Broker.
  </p>
  <p class="mt-2">
  This framework is intended to ensure you receive real market execution on your trades, while simultaneously allowing us to manage risk dynamically by routing existing positions or future orders to third parties for execution as we deem appropriate. We believe that such real market execution and dynamic risk management would not be possible or as cost-effective if trades were executed in simulated accounts.  Regardless of whether we act as counterparty to your trades, the gain or loss on your Funded Account is not calculated differently.  However, when we act as the counterparty to your trades, there is an inherent potential conflict of interest because your trades do not result in net gain or loss to us, as your trades would if we were not the direct counterparty.
  </p>
  `},

  {
    question: 'Am I subject to any position limits?',
    answer: `
  <p class="mt-2">
  We reserve the right to limit the number of open positions you may enter or maintain in the Funded Account at any time, and to revise in response to market conditions the drawdown levels at which trading in the Funded Account will be halted.
  </p>
  <p class="mt-2">
  We or the Broker reserve the right to refuse to accept any order.
  </p>
  `},

  {
    question: 'What are the rules for the Funded account?',
    answer: `
  <p class="mt-2">
  The rules for the Funded account are exactly the same as your Assessment account. However, with a Funded account, there is no cap on the profits you can generate.
  </p>
  `},

  {
    question: 'If I have a hard breach in my live account and there are gains, do I forfeit those gains?',
    answer: `
  <p class="mt-2">
  If you have gains in your Funded account at the time of a hard breach, you will still receive your portion of those gains.
  </p>
  <p class="mt-2">
  For example, if you have a $100,000 account and you grow that account to $110,000. Should you then have a hard breach we would close the account. Of the $10,000 in gains, you would be paid your 75% or 90% portion ($7,500 or $9,000).
  </p>
  `},

  {
    question: 'How do I withdraw the gains in my Funded Account?',
    answer: `
  <p class="mt-2">
  Traders can request a withdrawal of the gains in their Funded Account at any time in their trader dashboard, but no more frequently than once per thirty (30) days. So, if you make gain in your Funded Account, you can request a withdrawal.  When you are ready to withdraw the gains from your Funded Account, click the Withdraw Profits button in your trader dashboard and enter the amount to withdraw.
  </p>
  <p class="mt-2">
  When a withdrawal is approved, your max trailing drawdown will lock in at your starting balance.
  </p>
  <p class="mt-2">
  <b>Example</b>: You have taken an account from $100,000 to $120,000. You then request a withdrawal of $16,000. In this scenario, we would pay you $12,000 and we would retain $4,000 if you’re on a 75% gains split, or we would pay you $14,400 and we would retain $1,600 if you’re on a 90% gains split. This would also take the balance of the account down to $104,000, and your max trailing drawdown level is locked in at $100,000. So, you would then have $4,000 maximum you could lose on the account before it would violate the maximum trailing drawdown rule. If you take a full withdrawal of your share of the gains made in your account (in this example a full $20,000 withdrawal), it will also serve as a breach of your account.
  </p>
  `},

  {
    question: 'When can I withdraw the gains in my Funded Account and how does that affect my Maximum Trailing Drawdown?',
    answer: `
  <p class="mt-2">
  Your first withdrawal can be requested at any time.  Thereafter, you can request a withdrawal of the gains in your account every 30 days. When a withdrawal is approved, we will also withdraw our share of the gains, and your max trailing drawdown will lock in at your starting balance. The trailing drawdown does not reset when you request a withdrawal.
  </p>
  <p class="mt-2">
  <b>Example:</b> You have taken an account from $100,000 to $120,000. You then request a withdrawal of $16,000. In this scenario, you will receive $12,000 and we would retain $4,000. This would also take the balance of the account down to $104,000, and your Maximum Trailing Drawdown is locked in at $100,000. So, you would have $4,000 maximum you could lose on the account before it would violate the Maximum Trailing Drawdown rule. If you take a full withdrawal of the gains in your Funded Account, the Maximum Trailing Drawdown will still lock in at the starting balance and will therefore result in the forfeiting of your Funded Account, as your balance will trigger the Maximum Trailing Drawdown breach rule.
  </p>
  `},

]
},

{
  ///////////////////////////////////
  entryTitle: 'GENERAL QUESTIONS',
  ///////////////////////////////////
  items: [
  {
    question: 'Do I have to use one of your accounts for the Assessment or can I use my own?',
    answer: `
  <p class="mt-2">
  We have risk management software that is synced with the accounts we create. This allows us to analyze your performance in real time for achievements or rule violations. As such, you must use an account that we provide to you.
  </p>
   `},

  {
    question: 'What Countries are accepted?',
    answer: `
  <p class="mt-2">
  Subject to compliance with applicable laws and regulations, traders from all countries, excluding OFAC listed countries, can take part in our program.
  </p>
  `},

  {
    question: 'What is the minimum age I must be to be part of your program?',
    answer: `
  <p class="mt-2">
  You must be at least 18 years of age, or the applicable minimum legal age in your country, to purchase an assessment.
  </p>
  `},

  {
    question: 'Where do I track the progress of my account?',
    answer: `
  <p class="mt-2">
  Upon purchasing an Assessment, you will receive access to a trader dashboard where you can monitor your Assessment and Funded Accounts. The dashboard is updated every time we calculate metrics, which occurs roughly every 60 seconds. It is your responsibility to monitor your breach levels.
  </p>
  `},

  {
    question: 'What products can I trade?',
    answer: `
  <p class="mt-2">
  You can trade any products offered by the Broker, as such products may change from time to time. This includes FX pairs and CFD Indices, Metals and Cryptocurrencies.
  </p>
  `},

  {
    question: 'What is the leverage?',
    answer: `
  <p class="mt-2">
  We allow up to 20:1 leverage. Forex and Metals are 20:1. Indices are 10:1. Cryptos are 2:1
  </p>
  `},

  {
    question: 'What are the trading hours?',
    answer: `
  <p class="mt-2">
  Trading hours are set by the Broker. We do not have any control over the trading hours.
  </p>
  <p class="mt-2">
  Please note that holidays can have an impact on available trading hours.
  </p>
  <p class="mt-2">
  Additionally, pursuant to the no holding trades over the weekend rule, we close all open trades at 3:45pm EST on Fridays.
  </p>
  <p class="mt-2">
  <b>You can purchase an extra add-on to your trading account that allows you to keep trades open over the weekend.</b>
  </p>
  `},

  {
    question: 'Do your accounts charge commissions?',
    answer: `
  <p class="mt-2">
  We use the RAW accounts from the Broker. These accounts have commission charges for Forex and Equity Share CFDs. The other products do not carry a commission.
  </p>
  `},

  {
    question: 'What is the policy on Prohibited Trading Activity?',
    answer: `
  <p class="mt-2">
  You are also prohibited from using any trading strategy that is expressly prohibited by the Company or the Brokers it uses. Such prohibited trading (“Prohibited Trading”) shall include, but not be limited to:
  </p>
  <p class="mt-2">
        &bull; Exploiting errors or latency in the pricing and/or platform(s) provided by the Broker
  <br/>	&bull; Utilizing non-public and/or insider information
  <br/>	&bull; Front-running of trades placed elsewhere
  <br/>	&bull; Trading in any way that jeopardizes the relationship that the Company has with a Broker or may result in the canceling of trades
  <br/>	&bull; Trading in any way that creates regulatory issues for the Broker
  <br/>	&bull; Utilizing any third-party strategy, off-the-shelf strategy or one marketed to pass challenge accounts
  <br/>	&bull; Utilizing one strategy to pass an assessment and then utilizing a different strategy in a funded account, as determined by the Company in cooperation with Prop Account, LLC at their discretion
  <br/>	&bull; Holding a Single Share Equity CFD position into an earnings release pertaining to that underlying equity.  To avoid being in breach of this rule, you must close all such Single Share Equity CFD positions by 3:50 pm Eastern Time on the day of the release, if an aftermarket release, or on the preceding day, if a before market open release.  Violation of this rule will constitute an immediate, hard breach of your account and any gain or loss on said position will be removed from any calculations.
  <br/>	&bull; Entering into an Equity CFD at or near the end of the trading day with intent of profiting from the marketing gap between when the market closes and reopens on the subsequent trading day, as determined by the Company in its sole and absolute discretion.
  <br/>	&bull; Attempting to arbitrage an assessment account with another account with the Company or any third-party company, as determined by the Company in its sole and absolute discretion.
  <br/>	&bull; If the Company detects that your trading constitutes Prohibited Trading, your participation in the program will be terminated and may include forfeiture of any fees paid to the Company. Additionally, and before any Trader shall receive a funded account, the trading activity of the Trader under these Terms and Conditions shall be reviewed by both the Company and the Broker to determine whether such trading activity constitutes Prohibited Trading. In the case of Prohibited Trading, the Trader shall not receive a funded account.
  <br/>	&bull; Additionally, the Company reserves the right to disallow or block any Trader from participating in the program for any reason, in the Company’s sole and absolute discretion.
  </p>
  <p class="mt-2">
  To view all Prohibited Uses, please review our Terms and Conditions here,
  <u><a target="_blank" href="https://dashboardanalytix.com/client-terms-and-policies/?v=7516fd43adaa"> https://dashboardanalytix.com/client-terms-and-policies/?v=7516fd43adaa</a></u>.
  </p>
  `},

  {
    question: 'Can I trade during News Events?',
    answer: `
  <p class="mt-2">
  You can trade during the news provided that pricing data from the Broker continue to be provided.
<br/>
<br/>
  The only restriction is that you cannot open a new order 3 minutes before or after news events.
<br/>
<br/>
  You can modify a stop loss to an already existing position within this time period (3 minutes before and after the event).
<br/>
<br/>
  You can close an already existing position via a Stop Loss or manually within this time period (3 minutes before and after the event).
<br/>
<br/>
  A pending position that opens during this time period (3 minutes before and after the event) is allowed.
<br/>
<br/>
  For a definition of a "News Event" please use this website <u><a href="https://www.forexfactory.com/" target="_blank">https://www.forexfactory.com/<a></u> and the red folder filter to see news events for the week.
  </p>
  `},

  {
    question: 'How will I see the charge on my Statement?',
    answer: `
  <p class="mt-2">
  Charges come across in the name of Dashboardanalytix.com.
  </p>
  `},

  {
    question: 'How are taxes handled?',
    answer: `
  <p class="mt-2">
  When trading a Funded Account for our firm, you are treated as an independent contractor. As a result, you are responsible for any and all taxes on your gains.
  </p>
  `},

  {
    question: 'How are affiliates credited?',
    answer: `
  <p class="mt-2">
Affiliates are given credit for introducing new Customers to your firm:
<br/>
<br/>
1. Customers are registered to an affiliate during Customer Registration (i.e., when the Customer account is created). This is a separate process from Checkout.
<br/>
2. A Customer who creates an account that is NOT registered to an affiliate cannot be registered to an affiliate at a later point. At this point, they are no longer a new customer and therefore the affiliate has not introduced them to your firm.
<br/>
<br/>
A Customer who is registered to an affiliate will remain registered forever. An affiliate will receive affiliate credit for ALL orders from that customer for their lifetime.
  </p>
  `},

  {
    question: 'Can I have multiple Trader Funding Program accounts concurrently?',
    answer: `
  <p class="mt-2">
  Yes, you can have multiple Trader Funding Program accounts running concurrently, as long as they are NOT the same size. For example, you can have a $25k, a $50k and a $100k account, but you cannot have two $50k accounts.
  </p>
  `},

  {
    question: 'What Add-Ons are Available at Purchase?',
    answer: `
  <p class="mt-2">
       <b>1. Hold Over Weekend (10% Cost)</b> – Point-of-Sale add-on that disables “Flat for Weekend” requirement; this allows traders to keep positions open over the weekend.
  <br/><b>2. Gains Share Increased to 90% (20% Cost)</b> – Point-of-Sale add-on that increases a trader’s potential gains share for funded accounts to 90% (up from the standard 75%) of the gains.
  </p>
  `},

]
},
// sections end
];



